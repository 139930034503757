/**
 * 试验信息API
 */
import { fetch, post, del } from '../base/axios'

const URL = {
  listUrl: '/user/list/',
  roleListUrl: '/user/role/list/',
  addUrl: '/user/add/',
  delUrl: '/user/delete/'
}

/**
 * 查询用户
 * @param data
 * @returns {Promise}
 */
export function userList (data) {
  return fetch(URL.listUrl, data)
}

export function roleList () {
  return fetch(URL.roleListUrl)
}

export function addUser (form) {
  return post(URL.addUrl, form)
}

export function delUser (form) {
  return del(URL.delUrl, { data: form })
}

