<template>
  <div class="home">
  <Config/>
  </div>
  <el-backtop :visibility-height="10"></el-backtop>
</template>

<script>
// @ is an alias to /src
import Config from '@/components/Config/Config.vue'

export default {
  name: 'ConfigL',
  components: {
    Config
  }
}
</script>
